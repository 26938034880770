<template>
  <b-navbar
    wrapper-class="container"
    :mobile-burger="displayNavbarItems"
    close-on-click
  >
    <template slot="brand">
      <b-navbar-item
        tag="router-link"
        :to="{ name: 'home' }"
      >
        <img
          src="/corpoexpress.png"
          alt="Corpo Express"
        >
      </b-navbar-item>
    </template>
    <template
      v-if="displayNavbarItems"
      slot="end"
    >
      <b-navbar-item tag="div">
        <router-link
          :to="{ name: 'home' }"
          class="navbar-item--link animated-link"
        >
          {{ $t('navbar.home') }}
        </router-link>
      </b-navbar-item>
      <b-navbar-item tag="div">
        <router-link
          :to="{ name: 'incorporation' }"
          class="navbar-item--link animated-link"
        >
          {{ $t('navbar.incorporation') }}
        </router-link>
      </b-navbar-item>
      <b-navbar-item tag="div">
        <router-link
          :to="{ name: 'otherservices' }"
          class="navbar-item--link animated-link"
        >
          {{ $t('navbar.services') }}
        </router-link>
      </b-navbar-item>
      <b-navbar-item tag="div">
        <router-link
          :to="{ name: 'professionalaccess' }"
          class="navbar-item--link animated-link"
        >
          {{ $t('navbar.professionalAccess') }}
        </router-link>
      </b-navbar-item>
      <b-navbar-item tag="div">
        <router-link
          :to="{ name: 'contact' }"
          class="navbar-item--link animated-link"
        >
          {{ $t('navbar.contact') }}
        </router-link>
      </b-navbar-item>
      <b-navbar-item tag="div">
        <a
          @click="changeLanguage"
          class="navbar-item--link"
          href="#"
        >
          {{ oppositeLanguage }}
        </a>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
    displayNavbarItems() {
      return this.$route.name !== 'revision';
    },
    oppositeLanguage() {
      return this.$i18n.locale === 'fr' ? 'EN' : 'FR';
    }
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.oppositeLanguage.toLowerCase();
    }
  }
};
</script>
