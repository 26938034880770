import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home')
    },
    {
      path: '/en/',
      name: 'home-en',
      component: () => import('@/views/Home')
    },
    {
      path: '/fr/',
      name: 'home-fr',
      component: () => import('@/views/Home')
    },
    {
      path: '/incorporation',
      name: 'incorporation',
      component: () => import('@/views/Incorporation')
    },
    {
      path: '/en/incorporation',
      name: 'incorporation-en',
      component: () => import('@/views/Incorporation')
    },
    {
      path: '/fr/incorporation',
      name: 'incorporation-fr',
      component: () => import('@/views/Incorporation')
    },
    {
      path: '/incorporation/form',
      name: 'incorporation-form',
      component: () => import('@/views/IncorporationForm')
    },
    {
      path: '/req/form',
      name: 'req-form',
      component: () => import('@/views/ReqForm')
    },
    {
      path: '/professionalaccess',
      name: 'professionalaccess',
      component: () => import('@/views/ProfessionalAccess')
    },
    {
      path: '/en/professionalaccess',
      name: 'professionalaccess-en',
      component: () => import('@/views/ProfessionalAccess')
    },
    {
      path: '/fr/professionalaccess',
      name: 'professionalaccess-fr',
      component: () => import('@/views/ProfessionalAccess')
    },
    {
      path: '/professionalaccess/req',
      name: 'professionalaccess-req',
      component: () => import('@/views/ProfessionalAccess')
    },
    {
      path: '/professionalaccess/incorporation',
      name: 'professionalaccess-incorporation',
      component: () => import('@/views/ProfessionalAccess')
    },
    {
      path: '/otherservices',
      name: 'otherservices',
      component: () => import('@/views/OtherServices')
    },
    {
      path: '/en/otherservices',
      name: 'otherservices-en',
      component: () => import('@/views/OtherServices')
    },
    {
      path: '/fr/otherservices',
      name: 'otherservices-fr',
      component: () => import('@/views/OtherServices')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/Contact')
    },
    {
      path: '/en/contact',
      name: 'contact-en',
      component: () => import('@/views/Contact')
    },
    {
      path: '/fr/contact',
      name: 'contact-fr',
      component: () => import('@/views/Contact')
    },
    {
      path: '/order/form/:formId/confirm',
      name: 'confirmation',
      component: () => import('@/views/Confirmation')
    },
    {
      path: '/order/form/:formId/cancel',
      name: 'cancellation',
      component: () => import('@/views/Cancellation')
    },
    {
      path: '/revision/:revisionId',
      name: 'revision',
      component: () => import('@/views/Revision')
    },
    {
      path: '/incorporation-details/:id',
      name: 'incorporation-details',
      component: () => import('@/views/IncorporationDetails')
    },
    {
      path: '/req-details/:id',
      name: 'req-details',
      component: () => import('@/views/ReqDetails')
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

export default router
