import Vue from 'vue';
import App from './App.vue';
import VueMeta from 'vue-meta';
import router from './router';
import store from './store';
import i18n from './i18n';
import Buefy from 'buefy';
import moment from 'moment';
import 'moment/locale/fr';
import './fontawesome';
import { Auth0Plugin } from './auth';

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

Vue.use(VueMeta);

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  redirectUri: window.location.origin + '/professionalaccess',
  authorizationParams: {
    audience: process.env.VUE_APP_AUTH_AUDIENCE
  },
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

moment.locale = i18n.locale;

Vue.prototype.$moment = moment;
Vue.prototype.$CAPTCHA_SITE_KEY = process.env.VUE_APP_CAPTCHA_SITE_KEY;

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  i18n,
  store,
  router
}).$mount('#app');
