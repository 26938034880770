<template>
  <div id="app">
    <div class="app-container">
      <nav-bar v-if="!isFullpage"></nav-bar>
      <transition
          name="fade-in-down"
          mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </div>
    <site-footer v-if="!isFullpage"></site-footer>
  </div>
</template>

<script>
  import NavBar from '@/components/NavBar'
  import SiteFooter from '@/components/SiteFooter'
  import { saveLanguage } from '@/utils/language'

  export default {
    name: 'App',
    components: { NavBar, SiteFooter },
    created () {
      document.documentElement.lang = this.$i18n.locale
      this.$router.beforeEach((to, from, next) => {
        this.transition = to.meta.transition
          ? to.meta.transition
          : 'fade-in-down'
        next()
      })
    },
    computed: {
      isFullpage () {
        return ['maintenance', 'incorporation-form', 'req-form'].includes(this.$route.name)
      }
    },
    watch: {
      '$route.name' () {
        window.scrollTo(0, 0)
      },
      '$i18n.locale' (newLocale) {
        saveLanguage(newLocale)
      }
    }
  }
</script>

<style lang="scss">
  @import "styles/index";
</style>
