import axios from 'axios';
import { getInstance } from './auth';

axios.interceptors.request.use((config) => {
  return new Promise((resolve) => {
    const authService = getInstance();

    function addTokenToConfig () {
      if (authService.isAuthenticated) {
        authService.getTokenSilently()
          .then(token => {
            if (token) {
              config.headers.Authorization = `Bearer ${token}`;
            }
          })
          .finally(() => resolve(config));
      } else {
        resolve(config);
      }
    }

    if (!authService.loading) {
      addTokenToConfig();
    } else {
      authService.$watch('loading', (loading) => {
        if (loading === false) {
          addTokenToConfig();
        }
      });
    }
  });
}, function (error) {
  return Promise.reject(error);
});

export default {
  calculateCart (cartItems) {
    return axios({
      method: 'POST',
      baseURL: '/api/order/_calculateCart',
      data: cartItems
    });
  },
  createContactRequest (recaptchaToken, payload) {
    return axios({
      method: 'POST',
      baseURL: '/api/contactRequest',
      headers: {
        'x-recaptcha-token': recaptchaToken
      },
      data: payload
    });
  },
  createForm (recaptchaToken, formType, formPayload) {
    return axios({
      method: 'POST',
      baseURL: `/api/form/${formType}`,
      headers: { 'x-recaptcha-token': recaptchaToken, 'Content-Type': 'multipart/form-data' },
      data: formPayload
    });
  },
  createIncorporationForm (formType, formPayload) {
    return axios({
      method: 'POST',
      baseURL: `/api/professionalAccess/incorporation/form/${formType}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formPayload
    });
  },
  createReqForm (formPayload) {
    return axios({
      method: 'POST',
      baseURL: '/api/professionalAccess/req/form',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formPayload
    });
  },
  checkout (formId, cartItems) {
    return axios({
      method: 'POST',
      baseURL: `/api/order/form/${formId}/_checkout`,
      data: cartItems
    });
  },
  confirmOrder (formId, token, PayerID) {
    return axios({
      method: 'POST',
      baseURL: `/api/order/form/${formId}/_confirm`,
      params: {
        token,
        PayerID
      }
    });
  },
  cancelOrder (formId, token, PayerID) {
    return axios({
      method: 'POST',
      baseURL: `/api/order/form/${formId}/_cancel`,
      params: {
        token,
        PayerID
      }
    });
  },
  getRevision (revisionId) {
    return axios(`/api/revision/${revisionId}`);
  },
  submitRevision (revisionId, message, revisedValues) {
    return axios({
      method: 'POST',
      baseURL: `/api/revision/${revisionId}`,
      data: {
        message,
        revisedValues
      }
    });
  },
  getIncorporationForms (page, size, sort, direction) {
    return axios({
      method: 'GET',
      baseURL: '/api/professionalAccess/incorporation/form',
      params: {
        page,
        size,
        sort,
        direction: direction?.toUpperCase()
      }
    });
  },
  getReqForms (page, size, sort, direction) {
    return axios({
      method: 'GET',
      baseURL: '/api/professionalAccess/req/form',
      params: {
        page,
        size,
        sort,
        direction: direction?.toUpperCase()
      }
    });
  },
  getIncorporationFormById (formId) {
    return axios({
      method: 'GET',
      baseURL: `/api/professionalAccess/incorporation/form/${formId}`
    });
  },
  getReqFormById (formId) {
    return axios({
      method: 'GET',
      baseURL: `/api/professionalAccess/req/form/${formId}`
    });
  },
  getReqAttachmentsByFormId (formId) {
    return axios({
      method: 'GET',
      baseURL: `/api/professionalAccess/req/form/${formId}/file`
    });
  },
  deleteReqAttachment (attachmentId) {
    return axios({
      method: 'DELETE',
      baseURL: `/api/professionalAccess/req/form/file/${attachmentId}`
    });
  },
  deleteDraft (draftId) {
    return axios({
      method: 'DELETE',
      baseURL: `/api/professionalAccess/req/form/draft/${draftId}`
    });
  },
  fetchNeq (neq) {
    return axios({
      method: 'GET',
      baseURL: '/api/professionalAccess/req/form/info',
      params: {
        neq
      }
    });
  },
  saveAsDraft (formPayload) {
    return axios({
      method: 'POST',
      baseURL: '/api/professionalAccess/req/form/draft',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formPayload
    });
  },
  updateDraft (draftId, formPayload) {
    return axios({
      method: 'POST',
      baseURL: `/api/professionalAccess/req/form/draft/${draftId}/_update`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formPayload
    });
  },
  submitDraft (formPayload, draftId) {
    return axios({
      method: 'POST',
      baseURL: `/api/professionalAccess/req/form/draft/${draftId}/_submit`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formPayload
    });
  },
};
