import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faCalendarDay,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faEdit,
  faEnvelopeSquare,
  faEye,
  faExclamationCircle,
  faInfoCircle,
  faLock,
  faPaperPlane,
  faPhoneSquare,
  faSave,
  faSignOutAlt,
  faTimes,
  faTrash,
  faUpload,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import {
  faCheckCircle,
  faCheckSquare,
  faTimesCircle,
  faPlusSquare
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faCalendarDay,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCheckCircle,
  faCheckSquare,
  faClipboardList,
  faEdit,
  faEnvelopeSquare,
  faEye,
  faExclamationCircle,
  faInfoCircle,
  faLock,
  faPaperPlane,
  faPlusSquare,
  faPhoneSquare,
  faSave,
  faSignOutAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUpload,
  faUser)
Vue.component('vue-fontawesome',
  FontAwesomeIcon)
