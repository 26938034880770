function getLanguage () {
  const localPreferences = window.localStorage ? JSON.parse((window.localStorage.getItem('corpoexpress') || '{}')) : null

  const langs = [
    /* query   */ (document.location.search.match(/[?&]lang=(en|fr)(&|$)/i) || Array(2))[1],
    /* uri     */ (window.location.pathname.match(/^\/(en|fr)/) || Array(2))[1],
    /* storage */ localPreferences ? ((localPreferences?.lang === 'fr') ? 'fr' : 'en') : null,
    /* doc     */ document.documentElement.lang,
    /* default */ 'en'
  ]
  let lang = langs.filter(Boolean).shift()

  lang = saveLanguage(lang)

  return lang
}

function saveLanguage (lang) {
  if (['en', 'fr'].indexOf(lang) === -1) lang = 'en'

  if (window.localStorage) {
    const localPreferences = JSON.parse((window.localStorage.getItem('corpoexpress') || '{}'))
    localPreferences.lang = lang
    window.localStorage.setItem('corpoexpress', JSON.stringify(localPreferences))
  }

  return lang
}

export {
  getLanguage,
  saveLanguage
}
