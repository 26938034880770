import cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment';
import forms from '@/forms';

function getIncorporationFormData (locale, model, files) {
  const modelCopy = cloneDeep(model);
  modelCopy.locale = locale;

  let formData = new FormData();
  formData.append('formMappings', new Blob([JSON.stringify(modelCopy)], { type: 'application/json' }));
  files.forEach((file) => {
    formData.append('files', file);
  });

  return formData;
}

function getReqFormData (locale, model, directorFiles) {
  const modelCopy = cloneDeep(model);
  modelCopy.locale = locale;

  let formData = new FormData();
  formData.append('formMappings', new Blob([JSON.stringify(modelCopy)], { type: 'application/json' }));
  directorFiles.forEach((files, directorIndex) => {
    files.forEach((file, fileIndex) => {
      if (file instanceof File) {
        const renamedFile = new File([file], `dir-${directorIndex}-${fileIndex}-${file.name}`, { type: file.type });
        formData.append('files', renamedFile);
      }
    });
  });

  return formData;
}

function getIncorporationModel (form) {
  const model = JSON.parse(form);
  fixIncorporationModelDates(model);
  return Object.assign({}, forms.INCORPORATION_MODEL, model);
}

function getReqDraftModel (form) {
  const model = JSON.parse(form);
  fixReqModelDates(model);
  return Object.assign({}, forms.REQ_MODEL, model);
}

function getReqDraftDirectorFiles (attachments, reqDirector) {
  const directorFiles = [];
  for (let i = 0; i < reqDirector.length; i++)  directorFiles.push([]);

  if (attachments?.length) {
    attachments.forEach((attachment) => {
      const [, directorIndex, fileIndex, fileName] = [...attachment.name.matchAll(/^dir-(\d+)-(\d+)-(.*)$/g)]?.[0];
      while (fileIndex >= directorFiles[directorIndex].length) directorFiles[directorIndex].push([]);
      directorFiles[directorIndex][fileIndex] = { id: attachment.id, name: fileName };
    });
  }

  return directorFiles;
}

function fixIncorporationModelDates (model) {
  model.founder.birthdate = moment(model.founder.birthdate).toDate();
  model.director.forEach(d => d.birthdate = moment(d.birthdate).toDate());
  model.shareholder.forEach(s => s.birthdate = moment(s.birthdate).toDate());
  if (model.other.incorporationdate) model.other.incorporationdate = moment(model.other.incorporationdate).toDate();
  if (model.options.info.taxesdate) model.options.info.taxesdate = moment(model.options.info.taxesdate).toDate();
  if (model.options.info.salaryfirstdate) model.options.info.salaryfirstdate = moment(model.options.info.salaryfirstdate).toDate();
}

function fixReqModelDates (model) {
  model.reqDirector.forEach(d => {
    if (d.birthdate) d.birthdate = moment(d.birthdate).toDate();
  });
  model.beneficiary.forEach(s => {
    if (s.birthdate) s.birthdate = moment(s.birthdate).toDate();
    if (s.startDate) s.startDate = moment(s.startDate).toDate();
  });
  model.otherIndividual.forEach(o => {
    if (o.birthdate) o.birthdate = moment(o.birthdate).toDate();
  });
}

export {
  getIncorporationFormData,
  getIncorporationModel,
  getReqDraftModel,
  getReqDraftDirectorFiles,
  getReqFormData,
  fixIncorporationModelDates,
  fixReqModelDates
};
