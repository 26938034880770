import forms from '@/forms';
import cloneDeep from 'lodash-es/cloneDeep';

const state = {
  currentStep: -1,
  currentForm: undefined,
  currentUpdator: undefined,
  currentValidator: undefined,
  exitEarly: false,
  directorFiles: [],
  files: [],
  hasIncorporationAccess: false,
  instanceId: undefined,
  isNavigatingToPaypal: false,
  model: undefined,
  needsUpgrade: false,
  nextStepDisabled: false,
  recaptchaToken: undefined,
  steps: []
};

const mutations = {
  SET_CURRENT_UPDATOR (state, updator) {
    state.currentUpdator = updator;
  },
  SET_CURRENT_VALIDATOR (state, validator) {
    state.currentValidator = validator;
  },
  SET_EXIT_EARLY (state, exitEarly) {
    state.exitEarly = exitEarly;
  },
  SET_NEEDS_UPGRADE (state, needsUpgrade) {
    state.needsUpgrade = needsUpgrade;
  },
  SET_NEXT_STEP_DISABLED (state, nextStepDisabled) {
    state.nextStepDisabled = nextStepDisabled;
  },
  SET_FORM (state, form) {
    state.currentForm = form;
  },
  SET_HAS_INCORPORATION_ACCESS (state, hasIncorporationAccess) {
    state.hasIncorporationAccess = hasIncorporationAccess;
  },
  SET_INSTANCE_ID (state, instanceId) {
    state.instanceId = instanceId;
  },
  SET_IS_NAVIGATING_TO_PAYPAL (state, isNavigatingToPaypal) {
    state.isNavigatingToPaypal = isNavigatingToPaypal;
  },
  SET_FILES (state, files) {
    state.files = cloneDeep(files);
  },
  SET_DIRECTOR_FILES (state, directorFiles) {
    state.directorFiles = cloneDeep(directorFiles);
  },
  SET_MODEL (state, model) {
    state.model = model;
  },
  SET_MODEL_PAGE (state, { pageKey, pageModel }) {
    state.model[pageKey] = cloneDeep(pageModel);
  },
  SET_STEP (state, step) {
    state.currentStep = step;
  },
  SET_RECAPTCHA_TOKEN (state, recaptchaToken) {
    state.recaptchaToken = recaptchaToken;
  },
  SET_STEPS (state, steps) {
    state.steps = steps;
  },
  RESET_STATE (state) {
    state.currentStep = -1;
    state.currentForm = undefined;
    state.currentPage = undefined;
    state.currentValidator = undefined;
    state.exitEarly = false;
    state.directorFiles = [];
    state.files = [];
    state.hasIncorporationAccess = false;
    state.instanceId = undefined;
    state.isNavigatingToPaypal = false;
    state.model = undefined;
    state.needsUpgrade = false;
    state.nextStepDisabled = false;
    state.recaptchaToken = undefined;
    state.steps = [];
  }
};

const actions = {
  clearForm ({ commit }) {
    commit('RESET_STATE');
  },
  nextStep ({ dispatch, state }) {
    return state.currentValidator()
      .then(state.currentUpdator)
      .then(() => {
        dispatch('setStep', state.currentStep + 1);
        document.activeElement?.blur();
      })
      .catch(() => {
      });
  },
  previousStep ({ dispatch, state }) {
    return state.currentUpdator()
      .then(() => {
        dispatch('setStep', Math.max(0, state.currentStep - 1));
        document.activeElement?.blur();
      });
  },
  enableNextStep ({ commit }) {
    commit('SET_NEXT_STEP_DISABLED', false);
  },
  disableNextStep ({ commit }) {
    commit('SET_NEXT_STEP_DISABLED', true);
  },
  setDirectorFiles ({ commit }, directorFiles) {
    commit('SET_DIRECTOR_FILES', directorFiles);
  },
  setExitEarly ({ commit }, exitEarly) {
    commit('SET_EXIT_EARLY', exitEarly);
  },
  setForm ({ commit }, form) {
    try {
      if (!forms[form]) {
        commit('RESET_STATE', undefined);
      } else {
        commit('SET_FORM', form);
        commit('SET_MODEL', cloneDeep(forms[form].defaultModel));

        // TODO : Investigate persistent data following destroy
        const instanceId = Math.floor(Date.now() / 1000).toString(36);
        commit('SET_INSTANCE_ID', instanceId);

        if (forms[form].skipPreface) {
          commit('SET_STEP', 0);
        }
      }
    } catch (e) {
      console.error(e);
    }
  },
  setHasIncorporationAccess ({ commit }, hasIncorporationAccess) {
    commit('SET_HAS_INCORPORATION_ACCESS', hasIncorporationAccess);
  },
  setIsNavigatingToPaypal ({ commit }, isNavigatingToPaypal) {
    commit('SET_IS_NAVIGATING_TO_PAYPAL', isNavigatingToPaypal);
  },
  setModel ({ commit }, model) {
    commit('SET_MODEL', model);
  },
  resetModel ({ commit, state }) {
    commit('SET_FILES', []);
    commit('SET_DIRECTOR_FILES', []);
    commit('SET_MODEL', cloneDeep(forms[state.currentForm].defaultModel));
  },
  setNeedsUpgrade ({ commit }, needsUpgrade) {
    commit('SET_NEEDS_UPGRADE', needsUpgrade);
  },
  setRecaptchaToken ({ commit }, recaptchaToken) {
    commit('SET_RECAPTCHA_TOKEN', recaptchaToken);
  },
  setStep ({ commit }, step) {
    commit('SET_STEP', step);
  },
  setUpdator ({ commit }, updator) {
    commit('SET_CURRENT_UPDATOR', updator);
  },
  setValidator ({ commit }, validator) {
    commit('SET_CURRENT_VALIDATOR', validator);
  },
  startForm ({ dispatch }, form) {
    dispatch('viewMode/setViewModeCreate', null, { root: true });
    dispatch('clearForm');
    dispatch('setForm', form);
  },
  upgradeForm ({ commit, dispatch, state }) {
    if ('QC_NUM' === state.currentForm) dispatch('setForm', 'QC_NAME');
    if ('CA_NUM' === state.currentForm) dispatch('setForm', 'CA_NAME');
    commit('SET_NEEDS_UPGRADE', false);
  },
  updateModelPage ({ commit }, { pageKey, pageModel }) {
    commit('SET_MODEL_PAGE', { pageKey, pageModel });
  },
  updateSteps ({ commit, state }) {
    commit('SET_STEPS', state.currentForm ? forms[state.currentForm].steps.filter(step => {
      // TODO Fix this condition, it doesnt work
      if (step.component === 'payment') {
        return !state.hasIncorporationAccess;
      } else {
        return !(step.isHidden && step.isHidden(state.model));
      }
    }) : []);
  }
};

const getters = {
  areStepsEmpty (state) {
    return state.steps.length === 0;
  },
  isConfirmationStep (state) {
    return state.steps.length ? ['confirmation', 'reqConfirmation'].includes(state.steps[state.currentStep]?.component) : false;
  },
  isFederal (state) {
    return state.currentForm ? forms[state.currentForm].isFederal : false;
  },
  isFirstStep (state) {
    return state.currentStep <= 0;
  },
  isLastStep (state) {
    return state.steps.length - 1 === state.currentStep;
  },
  isNextStepDisabled () {
    return state.nextStepDisabled;
  },
  isQuebec (state) {
    return state.currentForm ? forms[state.currentForm].isQuebec : false;
  },
  shouldExitEarly (state) {
    return state.exitEarly;
  },
  shouldUpgradeForm (state) {
    return state.needsUpgrade;
  },
  supportsDraft (state) {
    return state.currentForm ? forms[state.currentForm].supportsDraft : false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
