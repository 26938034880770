const VIEW_MODE = {
  CREATE: 'create',
  REVIEW: 'review',
  REVISE: 'revise',
  READONLY: 'readonly'
}

const state = {
  viewMode: undefined
}

const mutations = {
  SET_VIEW_MODE (state, payload) {
    state.viewMode = payload
  }
}

const actions = {
  setViewModeCreate ({ commit }) {
    commit('SET_VIEW_MODE', VIEW_MODE.CREATE)
  },
  setViewModeReadOnly ({ commit }) {
    commit('SET_VIEW_MODE', VIEW_MODE.READONLY)
  },
  setViewModeReview ({ commit }) {
    commit('SET_VIEW_MODE', VIEW_MODE.REVIEW)
  },
  setViewModeRevise ({ commit }) {
    commit('SET_VIEW_MODE', VIEW_MODE.REVISE)
  },
  unsetViewMode ({ commit }) {
    commit('SET_VIEW_MODE', undefined)
  }
}

const getters = {
  isViewModeCreate (state) {
    return state.viewMode === VIEW_MODE.CREATE
  },
  isViewModeReadOnly (state) {
    return state.viewMode === VIEW_MODE.READONLY
  },
  isViewModeReview (state) {
    return state.viewMode === VIEW_MODE.REVIEW
  },
  isViewModeRevise (state) {
    return state.viewMode === VIEW_MODE.REVISE
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
