import api from '@/api';
import forms from '@/forms';
import jsonpath from 'jsonpath';
import { fixIncorporationModelDates } from '@/utils/helper';

const state = {
  fieldsToRevise: undefined,
  revision: undefined,
  revisionLocale: undefined
};

const mutations = {
  SET_REVISION (state, payload) {
    state.revision = payload;
    state.fieldsToRevise = JSON.parse(payload.fieldsToRevise);
  },
  SET_REVISION_LOCALE (state, payload) {
    state.revisionLocale = payload;
  },
  RESET_STATE (state) {
    state.fieldsToRevise = undefined;
    state.revision = undefined;
    state.revisionLocale = undefined;
  }
};

const actions = {
  fetchRevision ({ commit, dispatch }, revisionId) {
    return api
      .getRevision(revisionId)
      .then(({ data }) => {
        const model = JSON.parse(data.form);
        fixIncorporationModelDates(model);

        dispatch('viewMode/setViewModeRevise', null, { root: true });
        dispatch('form/setForm', data.formType, { root: true });
        dispatch('form/setModel', Object.assign({}, forms.INCORPORATION_MODEL, model), { root: true });
        dispatch('form/updateSteps', null, { root: true });
        commit('SET_REVISION', data.revision);
        commit('SET_REVISION_LOCALE', model.locale);
      })
      .catch((e) => {
        dispatch('viewMode/unsetViewMode', null, { root: true });
        dispatch('form/clearForm', null, { root: true });
        throw e;
      });
  },
  clearRevision ({ commit }) {
    commit('RESET_STATE');
  }
};

const getters = {
  getAgentMessage (state) {
    return state.revision?.agentMessage;
  },
  getRevisedValues (state, getters, rootState) {
    const revisedValues = {};
    Object.keys(state.fieldsToRevise)
      .forEach(jsonPath => {
        revisedValues[jsonPath] = jsonpath.value(rootState.form.model, jsonPath);
      });
    return revisedValues;
  },
  getFieldToReviseMessage (state) {
    return (jsonPath) => state.fieldsToRevise?.[jsonPath];
  },
  isFieldToRevise (state) {
    return (jsonPath) => state.fieldsToRevise?.[jsonPath] !== undefined;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
