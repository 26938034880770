const isHideOfficerPage = (model) => {
  return model?.director?.length <= 1;
};

const isHideSharePage = (model) => {
  return model?.shareholder?.length <= 1;
};

const INCORPORATION_MODEL = {
  founder: {
    firstname: '',
    lastname: '',
    birthdate: undefined,
    phone: '',
    email: '',
    address: '',
    city: '',
    postalcode: '',
    province: 'QC',
  },
  professionalOrder: {
    isProfessional: false,
    order: undefined
  },
  companyName: {
    frenchname: '',
    englishname: '',
    reasons: '',
    activities: '',
    territory: ''
  },
  headOffice: {
    address: '',
    city: '',
    postalcode: '',
    province: 'QC'
  },
  director: [{
    firstname: '',
    lastname: '',
    address: '',
    city: '',
    postalcode: '',
    province: 'QC',
    role: '',
    birthdate: undefined,
    ssn: '',
    assessmentnumber: '',
    isDirector: false,
    isTreasurer: false,
    isSecretary: false
  }],
  shareholder: [{
    individual: true,
    firstname: '',
    lastname: '',
    birthdate: undefined,
    corporationname: '',
    neq: '',
    address: '',
    city: '',
    postalcode: '',
    province: 'QC',
    isParticipatingShares: true,
    isVotingShares: true,
    votingSharesPercentage: 0,
    participatingSharesPercentage: 0
  }],
  other: {
    activities: '',
    employees: 'none',
    asap: true,
    incorporationdate: undefined,
    fiscalyearend: 11,
    accountant: '',
    financialinstitution: ''
  },
  options: {
    physicalbook: false,
    physicalbookinfo: {
      address: '',
      city: '',
      postalcode: '',
      province: 'QC'
    },
    numbers: 'none',
    info: {
      asap: true,
      taxesdate: undefined,
      headofficephone: '',
      headofficefax: '',
      headofficeemail: '',
      estimatedrevenue: '',
      declarationperiod: 'monthly',
      salaryfirstdate: undefined,
      salaryfrequency: '26',
      salarytotal: '',
      employeestype: 'annual',
      employeescount: '1to5',
      estimateddeductions: 'lessthan1000'
    }
  },
  payment: {}
};

const REQ_MODEL = {
  neq: {
    neq: '',
    nomEntreprise: '',
    adresse: '',
    regimeCourant: '',
    nbSalarie: '',
    secteurActivite: {
      premier: {
        code: '',
        activite: ''
      },
      deuxieme: {
        code: '',
        activite: ''
      }
    },
    actionnaire: {
      premier: {
        nom: '',
        prenom: '',
        adresse: '',
        typePersonne: ''
      },
      deuxieme: {
        nom: '',
        prenom: '',
        adresse: '',
        typePersonne: ''
      },
      troisieme: {
        nom: '',
        prenom: '',
        adresse: '',
        typePersonne: ''
      }
    },
    administrateur: [{
      nom: '',
      prenom: '',
      adresse: '',
      fonctions: ''
    }]
  },
  validateInfo: {
    isValid: undefined,
    changes: ''
  },
  reqDirector: [],
  beneficiary: [],
  otherIndividual: [],
  reqConfirmation: {
    additionalInfo: ''
  }
};

export default {
  INCORPORATION_MODEL,
  REQ_MODEL,
  QC_NUM: {
    defaultModel: INCORPORATION_MODEL,
    isFederal: false,
    isQuebec: true,
    steps: [
      {
        component: 'founder'
      },
      {
        component: 'professionalOrder'
      },
      {
        component: 'headOffice'
      },
      {
        component: 'director'
      },
      {
        component: 'officer',
        isHidden: isHideOfficerPage
      },
      {
        component: 'shareholder'
      },
      {
        component: 'share',
        isHidden: isHideSharePage
      },
      {
        component: 'other'
      },
      {
        component: 'options'
      },
      {
        component: 'confirmation'
      },
      {
        component: 'payment'
      }
    ]
  },
  QC_NAME: {
    defaultModel: INCORPORATION_MODEL,
    isFederal: false,
    isQuebec: true,
    steps: [
      {
        component: 'founder'
      },
      {
        component: 'professionalOrder'
      },
      {
        component: 'companyName'
      },
      {
        component: 'headOffice'
      },
      {
        component: 'director'
      },
      {
        component: 'officer',
        isHidden: isHideOfficerPage
      },
      {
        component: 'shareholder'
      },
      {
        component: 'share',
        isHidden: isHideSharePage
      },
      {
        component: 'other'
      },
      {
        component: 'options'
      },
      {
        component: 'confirmation'
      },
      {
        component: 'payment'
      }
    ]
  },
  CA_NUM: {
    defaultModel: INCORPORATION_MODEL,
    isFederal: true,
    isQuebec: false,
    steps: [
      {
        component: 'founder'
      },
      {
        component: 'professionalOrder'
      },
      {
        component: 'headOffice'
      },
      {
        component: 'director'
      },
      {
        component: 'officer',
        isHidden: isHideOfficerPage
      },
      {
        component: 'shareholder'
      },
      {
        component: 'share',
        isHidden: isHideSharePage
      },
      {
        component: 'other'
      },
      {
        component: 'options'
      },
      {
        component: 'confirmation'
      },
      {
        component: 'payment'
      }
    ]
  },
  CA_NAME: {
    defaultModel: INCORPORATION_MODEL,
    isFederal: true,
    isQuebec: false,
    steps: [
      {
        component: 'founder'
      },
      {
        component: 'professionalOrder'
      },
      {
        component: 'companyName'
      },
      {
        component: 'headOffice'
      },
      {
        component: 'director'
      },
      {
        component: 'officer',
        isHidden: isHideOfficerPage
      },
      {
        component: 'shareholder'
      },
      {
        component: 'share',
        isHidden: isHideSharePage
      },
      {
        component: 'other'
      },
      {
        component: 'options'
      },
      {
        component: 'confirmation'
      },
      {
        component: 'payment'
      }
    ]
  },
  REQ: {
    defaultModel: REQ_MODEL,
    skipPreface: true,
    supportsDraft: true,
    steps: [
      {
        component: 'neq'
      },
      {
        component: 'validateInfo'
      },
      {
        component: 'reqDirector'
      },
      {
        component: 'beneficiary'
      },
      {
        component: 'otherIndividual'
      },
      {
        component: 'reqConfirmation'
      }
    ]
  }
};
