<template>
  <footer class="footer site-footer">
    <div class="container">
      <div class="columns">
        <div class="column">
          <img
              src="/corpoexpress-white.png"
              alt="Corpo Express"
              class="site-footer__logo"
          >
          <p>© 2023, Corpo Express</p>
          <p>{{ $t('footer.developedBy') }}</p>
        </div>
        <div class="column">
          <h2 class="is-size-5">{{ $t('footer.about') }}</h2>
          <p>{{ $t('footer.aDivisionOf') }}</p>
          <a
              href="https://www.pmeavocats.com"
              target="_blank"
              rel="noopener noreferrer"
              title="PME Avocats"
          >
            www.pmeavocats.com
          </a>
        </div>
        <div class="column">
          <h2 class="is-size-5">{{ $t('footer.contactUs') }}</h2>
          <div class="is-align-center-flex">
            <b-icon icon="phone-square"></b-icon>
            <span class="mr-1">{{ $t('footer.phoneNumber') }} :</span><a href="tel:+15143941008">514-394-1008</a>
          </div>
          <div class="is-align-center-flex">
            <b-icon icon="envelope-square"></b-icon>
            <span class="mr-1">{{ $t('footer.email') }} :</span><a href="mailto:info@corpoexpress.com">info@corpoexpress.com</a>
          </div>
        </div>
      </div>
      <div class="content is-small">
        <p>{{ $t('footer.summary') }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'SiteFooter'
  }
</script>
